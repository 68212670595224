import moment from "moment";

const initalState = {
  language: "English",
  portfolioId: 0,
  clinicianId: 0,
  status: "All Status",
  fromDate: moment()?.subtract(14, "days"),
  toDate: moment()?.add(14, "days"),
  fromDatePending: moment()?.subtract(14, "days"),
  toDatePending: moment()?.add(14, "days"),
  fromDateConfirmId: moment()?.subtract(14, "days"),
  toDateConfirmId: moment()?.add(14, "days"),
  fromDateConfirmService: moment()?.subtract(14, "days"),
  toDateConfirmService: moment()?.add(14, "days"),
  fromDateSchedule: moment()?.subtract(14, "days"),
  toDateSchedule: moment()?.add(14, "days"),
  fromDateInvoice: moment()?.subtract(14, "days"),
  toDateInvoice: moment()?.add(14, "days"),
  fromDateInvSum: moment()?.subtract(14, "days"),
  toDateInvSum: moment()?.add(14, "days"),
  fromDateSum: moment()?.subtract(14, "days"),
  toDateSum: moment()?.add(14, "days"),
  fromDateApp: moment()?.subtract(14, "days"),
  toDateApp: moment()?.add(14, "days"),
  activeTab: "Portfolio",
  activeList: "Pending",
  pendingActiveList: "Pending",
  confirmedActiveList: "Confirmed",

  patientStatus: "Active"
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATE_LOGGEDINUSER": return { ...state, loggedInUserId: action?.payload };
    case "CHANGESTATE":
      return {
        ...state,
        ...action?.payload,
      };

    default:
      return state;
  }
};

export { reducer };