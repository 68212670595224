import { getUserfromSS } from "../ConfigFiles/SmallFunctions";
import {
  AiOutlineStock,
  AiOutlineGroup,
  AiOutlineDashboard,
  AiOutlineMedicineBox,
  AiOutlineAudit,
  AiOutlineCluster,
} from "react-icons/ai";
import { BiTestTube, BiPurchaseTag, BiCategoryAlt, BiSolidOffer, BiLocationPlus } from "react-icons/bi";
import { LuFileArchive } from "react-icons/lu";
import {
  BsCardChecklist,
  BsHospital,
  BsWindowDock,
  BsCalendar2Plus,
  BsFileEarmarkDiff,
  BsBroadcastPin,
  BsJournalMedical,
  BsCardList,
  BsQuestionDiamond,
  BsPersonCheck,
  BsFileEarmarkMedical,
  BsBagPlus,
} from "react-icons/bs";
import { CiDiscount1 } from "react-icons/ci";
import { FaAmbulance, FaStethoscope, FaUserMd, FaNotesMedical, FaFileInvoiceDollar } from "react-icons/fa";
import { GiMedicines, GiTestTubes, GiSellCard, GiConfirmed } from "react-icons/gi";
import { RiPassportFill, RiRoadMapLine } from "react-icons/ri";
import { GoPackage } from "react-icons/go";
import { ImEnter, ImLab } from "react-icons/im";
import { BsGraphUpArrow } from "react-icons/bs";
import {
  MdAddShoppingCart,
  MdOutlineManageAccounts,
  MdOutlineMedicalInformation,
  MdOutlineFactory,
  MdMiscellaneousServices,
  MdOutlineShareLocation,
  MdAttachMoney,
  MdOutlineReceiptLong,
  MdOutlineFreeCancellation,
  MdOutlineInventory2,
  MdOutlineFamilyRestroom,
  MdFilterTiltShift,
  MdHomeRepairService,
  MdOutlinePersonalInjury,
  MdOutlinePending,
  MdOutlineBookmarkAdded,
  MdOutlineBookmarkAdd,
} from "react-icons/md";
import {
  RiFloodLine,
  RiShieldUserLine,
  RiMedicineBottleLine,
  RiLockPasswordLine,
  RiExchangeDollarLine,
} from "react-icons/ri";
import { TbTestPipe2, TbEmergencyBed, TbSteeringWheel, TbListDetails, TbCheckupList, TbFileReport, TbUserStar, TbCalendarCancel } from "react-icons/tb";
import { HiOutlineUserGroup, HiOutlineViewGridAdd } from "react-icons/hi";
import { VscReferences } from "react-icons/vsc";
import { css } from "../ConfigFiles/Consts";
import { MdInventory2 } from "react-icons/md";
import { GrResources, GrServices, GrMapLocation, GrUserManager, GrUserSettings } from "react-icons/gr";
import { LiaFileInvoiceSolid, LiaFileMedicalSolid, LiaUserTieSolid } from "react-icons/lia";


export const sidenavbars = [

  {
    linkName: "Dashboard",
    name: "Dashboard",
    icon: <AiOutlineDashboard size={css.iconSize2} />,
    route: "/v1/service/admindashboard",
  },
  // {
  //   name: "Calendar",
  //   icon: <BsCalendar2Plus size={css.iconSize2} />,
  //   route: "/v1/service/appointment/calender",
  // },
  {
    linkName: "Map",
    name: "Map",
    icon: <RiRoadMapLine size={css.iconSize2} />,
    route: "/v1/service/appointment/map-appointments",
  },
  // {
  //   name: "Bookings",
  //   icon: <BsCardList size={css.iconSize2} />,
  //   route: "/v1/service/appointment/appointments",
  // },
  // {
  //   name: "Schedule Bookings",
  //   icon: <BsCalendar2Plus size={css.iconSize2} />,
  //   route: "/v1/service/appointment/all-appointments",
  // },
  {
    linkName: "Booking",
    name: "Bookings",
    icon: <BsCardList size={css.iconSize2} />,
    // route: "/v1/service/patient/all-patient",
    master: true,

    child: [
      {
        linkName: "Pending Booking",
        name: "Pending Bookings",
        icon: <MdOutlinePending size={css.iconSize2} />,
        route: "/v1/service/appointment/pending-appointments",
      },
      // {
      //   name: "Confirmed Bookings",
      //   icon: <GiConfirmed size={css.iconSize2} />,
      //   route: "/v1/service/appointment/confirmed-appointments",
      // },
      {
        linkName: "Confirmed Booking-ID",
        name: "Confirmed Booking-ID",
        icon: <GiConfirmed size={css.iconSize2} />,
        route: "/v1/service/appointment/appointments-booking-id",
      },
      {
        linkName: "Confirmed Booking-Service",
        name: "Confirmed Booking-Service",
        icon: <MdOutlineBookmarkAdd size={css.iconSize2} />,
        route: "/v1/service/appointment/appointments-booking-service",
      },
      {
        linkName: "Scheduled Appointment-ID",
        name: "Scheduled Appointment-ID",
        icon: <MdOutlineBookmarkAdded size={css.iconSize2} />,
        route: "/v1/service/appointment/scheduled-appointments",
      },
      // {
      //   linkName: "Cancelled Bookings",
      //   name: "Cancelled Bookings",
      //   icon: <TbCalendarCancel size={css.iconSize2} />,
      //   route: "/v1/service/appointment/cancelled-appointments",
      // },
      // {
      //   linkName: "Schedule By User",
      //   name: "Appointments  Today",
      //   icon: <BsCardList size={css.iconSize2} />,
      //   route: "/v1/service/appointment/appointments-today",
      // },
      // {
      //   linkName: "Schedule By User",
      //   name: "Appointments Week",
      //   icon: <BsCardList size={css.iconSize2} />,
      //   route: "/v1/service/appointment/appointments-week",
      // },
      // {
      //   linkName: "Schedule By User",
      //   name: "Appointments Completed",
      //   icon: <BsCardList size={css.iconSize2} />,
      //   route: "/v1/service/appointment/appointments-completed",
      // },
      // {
      //   linkName: "Schedule By User",
      //   name: "UnSchedule Appointments",
      //   icon: <BsCardList size={css.iconSize2} />,
      //   route: "/v1/service/appointment/unscheduled-appointments",
      // },
    ],
  },
  {
    linkName: "Patient",
    name: "Archive Appointments",
    icon: <LuFileArchive size={css.iconSize2} />,
    route: "/v1/service/appointment/all-appointments",
  },
  {
    linkName: "Patient",
    name: "Patient",
    icon: <MdOutlinePersonalInjury size={css.iconSize2} />,
    // route: "/v1/service/patient/all-patient",
    master: true,
    child: [
      {
        linkName: "Patient Details",
        name: "Patient Details",
        icon: <RiShieldUserLine size={css.iconSize2} />,
        route: "/v1/service/patient/all-patient",
      },
      {
        linkName: "Patient Medical Files",
        name: "Patient Medical Files",
        icon: <LiaFileMedicalSolid size={css.iconSize2} />,
        route: "/v1/service/patient/patient-medical-files",
      },
    ],
  },

  {
    linkName: "Resources",
    name: "Resources",
    icon: <GrResources size={css.iconSize2} />,
    master: true,
    child: [
      {
        linkName: "Users",
        name: "Users",
        icon: <FaUserMd size={css.iconSize2} />,
        route: "/v1/service/provider/all-providers",
      },
      {
        linkName: "Vehicles",
        name: "Vehicles",
        icon: <FaAmbulance size={css.iconSize2} />,
        route: "/v1/service/vehile/all-vehiles",
      },
      // {
      //   name: "Driver",
      //   icon: <TbSteeringWheel size={css.iconSize2} />,
      //   route: "/v1/service/driver/all-drivers",
      // },
    ],
  },
  // { name: "Master",
  //   icon: <MdFilterTiltShift size={css.iconSize2} />,
  //   route: "/v1/service/master/all-masters",},

  {
    linkName: "Services",
    name: "Services",
    icon: <GrServices size={css.iconSize2} />,
    master: true,
    child: [
      {
        linkName: "Services",
        name: "Services",
        icon: <FaUserMd size={css.iconSize2} />,
        route: "/v1/service/service-master/all-service",
      },
      {
        linkName: "Packages",
        name: "Packages",
        icon: <BsBagPlus size={css.iconSize2} />,
        route: "/v1/service/service-master/all-package",
      },
      {
        linkName: "Medicines",
        name: "Medicines",
        icon: <RiMedicineBottleLine size={css.iconSize2} />,
        route: "/v1/service/medicine/all-medicine",
      },
    ],
  },
  {
    linkName: "Settings",
    name: "Settings",
    icon: <MdFilterTiltShift size={css.iconSize2} />,
    route: "/v1/service/master/all-masters",
    master: true,
    child: [
      {
        linkName: "Sub-Speciality",
        name: "Sub Speciality",
        icon: <AiOutlineCluster size={css.iconSize2} />,
        route: "/v1/service/subspeciality/all-sub-speciality",
      },
      {
        linkName: "Provider Type",
        name: "Provider Type",
        icon: <TbUserStar size={css.iconSize2} />,
        route: "/v1/service/usertype/alluser-type",
      },
      {
        linkName: "Employment Type",

        name: "Employment Type",
        icon: <LiaUserTieSolid size={css.iconSize2} />,
        route: "/v1/service/employementtype/all-employment-type",
      },

      {
        linkName: "Portfolio",
        name: "Portfolio",
        icon: <RiPassportFill size={css.iconSize2} />,
        route: "/v1/service/portfolio/all-portfolio",
      },
      {
        linkName: "Service Sub-Level",
        name: "Service Sub Level",
        icon: <MdHomeRepairService size={css.iconSize2} />,
        route: "/v1/service/serviceSubLevel/all-service-sublevels",
      },
      {
        linkName: "Work Location",
        name: "Work Location",
        icon: <BiLocationPlus size={css.iconSize2} />,
        route: "/v1/service/locations/all-locations",
      },
    ],
  },
  // {
  //   name: "Services",
  //   icon: <GrServices size={css.iconSize2} />,
  //   route: "/v1/service/serviceRc/all-service-master",
  // },

  // {
  //   name: "Medicine",
  //   icon: <RiMedicineBottleLine size={css.iconSize2} />,
  //   route: "/v1/service/medicine/all-medicine",
  // },
  {
    linkName: "Report",
    name: "Reports",
    icon: <GrResources size={css.iconSize2} />,
    master: true,
    child: [
      {
        linkName: "Detailed Summary By Booking",
        name: "Detailed Summary by Booking",
        icon: <FaUserMd size={css.iconSize2} />,
        route: "/v1/service/reports/summary-by-booking",
      },
      {
        linkName: "Detailed Summary By Service",
        name: "Detailed Summary by Service",
        icon: <TbFileReport size={css.iconSize2} />,
        route: "/v1/service/reports/summary-by-service",
      },
      {
        linkName: "Invoice Summary",
        name: "Invoice Summary",
        icon: <LiaFileInvoiceSolid size={css.iconSize2} />,
        route: "/v1/service/reports/invoice-summary",
      },
      {
        linkName: "Invoice Summary-Service",
        name: "Invoice Summary Service",
        icon: <BsFileEarmarkMedical size={css.iconSize2} />,
        route: "/v1/service/reports/invoice-summary-service",
      },
    ],
  },
  // {
  //   name: "Supply Request",
  //   icon: <MdOutlineInventory2 size={css.iconSize2} />,
  //   route: "/v1/service/supplyRequest/all-supplyRequest",
  // },
  {
    linkName: "Audit Log",
    name: "Audit Log",
    icon: <AiOutlineAudit size={css.iconSize2} />,
    route: "/v1/service/auditlog",
  },
  {
    linkName: "User Group",
    name: "User Access",
    icon: < GrUserSettings size={css.iconSize2} />,
    master: true,
    child: [
      {
        linkName: "User Group",
        name: "User Group",
        icon: <HiOutlineUserGroup size={css.iconSize2} />,
        route: "/v1/service/useraccess/user-group",
      },
      {
        linkName: "User Access",
        name: "User Access",
        icon: <GrResources size={css.iconSize2} />,
        route: "/v1/service/useraccess/all-user-access",
      },

    ],
  },
  {
    linkName: "Family Members",
    name: "Family Members",
    icon: <MdOutlineFamilyRestroom size={css.iconSize2} />,
    route: "/v1/service/familyMembers/all-familyMembers",
  },
  {
    linkName: "Add Vitals",
    name: "Add Vitals",
    icon: <TbListDetails size={css.iconSize2} />,
    route: "/v1/service/patient/vitals/all-vitals",
  },
];



export const sidenavbar = () => {
  let roleId = getUserfromSS()?.roleId;
  switch (roleId) {
    case 1:
      return [
        {
          name: "Dashboard",
          icon: <AiOutlineDashboard size={css.iconSize2} />,
          route: "/v1/service/admindashboard",
        },
        // {
        //   name: "Calendar",
        //   icon: <BsCalendar2Plus size={css.iconSize2} />,
        //   route: "/v1/service/appointment/calender",
        // },
        // {
        //   name: "Map",
        //   icon: <RiRoadMapLine size={css.iconSize2} />,
        //   route: "/v1/service/appointment/map-appointments",
        // },
        // {
        //   name: "Appointments",
        //   icon: <BsCardList size={css.iconSize2} />,
        //   route: "/v1/service/appointment/all-appointments",
        // },
        {
          name: "Patient",
          icon: <RiShieldUserLine size={css.iconSize2} />,
          route: "/v1/service/patient/all-patient",
        },
        {
          name: "Manager",
          icon: <GrUserManager size={css.iconSize2} />,
          route: "/v1/service/manager/all-manager",
        },
        {
          name: "Provider",
          icon: <FaUserMd size={css.iconSize2} />,
          route: "/v1/service/provider/all-providers",
        },
        {
          name: "Master",
          icon: <MdFilterTiltShift size={css.iconSize2} />,
          route: "/v1/service/master/all-masters",
        },
        {
          name: "Services",
          icon: <GrServices size={css.iconSize2} />,
          route: "/v1/service/serviceRc/all-service-master",
          // master: true,
          // child: [
          //   {
          //     name: "Portfolio",
          //     icon: <RiPassportFill size={css.iconSize2} />,
          //     route: "/v1/service/portfolio/all-portfolio",
          //   },
          //   {
          //     name: "Service Sub Level",
          //     icon: <MdHomeRepairService size={css.iconSize2} />,
          //     route: "/v1/service/serviceSubLevel/all-service-sublevels",
          //   },
          //   {
          //     name: "Service master",
          //     icon: <MdMiscellaneousServices size={css.iconSize2} />,
          //     route: "/v1/service/service/all-service",
          //   },
          // ],
        },
        // {
        //   name: "Lab Tests",
        //   icon: <BiTestTube size={css.iconSize2} />,
        //   route: "/v1/service/labtest/all-test",
        // },
        {
          name: "Medicine",
          icon: <RiMedicineBottleLine size={css.iconSize2} />,
          route: "/v1/service/medicine/all-medicine",
        },
        // {
        //   name: "Audit Log",
        //   icon: <AiOutlineAudit size={css.iconSize2} />,
        //   route: "/v1/service/auditlog",
        // },
        {
          name: "Offers",
          icon: <BiSolidOffer size={css.iconSize2} />,
          route: "/v1/service/offer/all-offers",
        },
      ];
    case 2:
      return [
        {
          name: "Dashboard",
          icon: <AiOutlineDashboard size={css.iconSize2} />,
          route: "/v1/service/admindashboard",
        },
        {
          name: "Calendar",
          icon: <BsCalendar2Plus size={css.iconSize2} />,
          route: "/v1/service/appointment/calender",
        },
        {
          name: "Map",
          icon: <RiRoadMapLine size={css.iconSize2} />,
          route: "/v1/service/appointment/map-appointments",
        },
        {
          name: "Bookings",
          icon: <BsCardList size={css.iconSize2} />,
          route: "/v1/service/appointment/appointments",
        },
        {
          name: "Schedule",
          icon: <BsCardList size={css.iconSize2} />,
          route: "/v1/service/appointment/all-appointments",
        },
        {
          name: "Patient",
          icon: <RiShieldUserLine size={css.iconSize2} />,
          route: "/v1/service/patient/all-patient",
        },
        {
          name: "Supply Request",
          icon: <MdInventory2 size={css.iconSize2} />,
          route: "/v1/service/supplyRequest/all-supplyRequest",
        },
        // {
        //   name: "Audit Log",
        //   icon: <AiOutlineAudit size={css.iconSize2} />,
        //   route: "/v1/service/auditlog",
        // },

        // {
        //   name: "Provider",
        //   icon: <FaUserMd size={css.iconSize2} />,
        //   route: "/v1/service/provider/all-providers",
        // },
      ];
    case 3:
      return [
        {
          name: "Dashboard",
          icon: <AiOutlineDashboard size={css.iconSize2} />,
          path: "/v1/service/admindashboard",
        },
        // {
        //   name: "Calendar",
        //   icon: <BsCalendar2Plus size={css.iconSize2} />,
        //   path: "/v1/service/appointment/calender",
        // },
        {
          name: "Map",
          icon: <RiRoadMapLine size={css.iconSize2} />,
          path: "/v1/service/appointment/map-appointments",
        },
        // {
        //   name: "Bookings",
        //   icon: <BsCardList size={css.iconSize2} />,
        //   path: "/v1/service/appointment/appointments",
        // },
        // {
        //   name: "Schedule Bookings",
        //   icon: <BsCalendar2Plus size={css.iconSize2} />,
        //   path: "/v1/service/appointment/all-appointments",
        // },
        {
          name: "Bookings",
          icon: <BsCardList size={css.iconSize2} />,
          // path: "/v1/service/patient/all-patient",
          master: true,

          child: [
            {
              name: "Pending Bookings",
              icon: <MdOutlinePending size={css.iconSize2} />,
              path: "/v1/service/appointment/pending-appointments",
            },
            // {
            //   name: "Confirmed Bookings",
            //   icon: <GiConfirmed size={css.iconSize2} />,
            //   path: "/v1/service/appointment/confirmed-appointments",
            // },
            {
              name: "Confirmed Booking-ID",
              icon: <MdOutlineBookmarkAdded size={css.iconSize2} />,
              path: "/v1/service/appointment/appointments-booking-id",
            },
            {
              name: "Confirmed Booking-Service",
              icon: <BsCardList size={css.iconSize2} />,
              path: "/v1/service/appointment/appointments-booking-service",
            },
            {
              name: "Scheduled Appointment-ID",
              icon: <MdOutlineBookmarkAdded size={css.iconSize2} />,
              path: "/v1/service/appointment/scheduled-appointments",
            },
            {
              name: "Scheduled Appointment-Service",
              icon: <BsCardList size={css.iconSize2} />,
              path: "/v1/service/appointment/appointments-booking-service",
            },
          ],
        },
        {
          name: "Patient",
          icon: <MdOutlinePersonalInjury size={css.iconSize2} />,
          // path: "/v1/service/patient/all-patient",
          master: true,

          child: [
            {
              name: "Patient Details",
              icon: <RiShieldUserLine size={css.iconSize2} />,
              path: "/v1/service/patient/all-patient",
            },
            {
              name: "Patient Medical Files",
              icon: <LiaFileMedicalSolid size={css.iconSize2} />,
              path: "/v1/service/patient/patient-medical-files",
            },
          ],
        },

        {
          name: "Resources",
          icon: <GrResources size={css.iconSize2} />,
          master: true,
          child: [
            {
              name: "Users",
              icon: <FaUserMd size={css.iconSize2} />,
              path: "/v1/service/provider/all-providers",
            },
            {
              name: "Vehicles",
              icon: <FaAmbulance size={css.iconSize2} />,
              path: "/v1/service/vehile/all-vehiles",
            },
            // {
            //   name: "Driver",
            //   icon: <TbSteeringWheel size={css.iconSize2} />,
            //   path: "/v1/service/driver/all-drivers",
            // },
          ],
        },
        // { name: "Master",
        //   icon: <MdFilterTiltShift size={css.iconSize2} />,
        //   path: "/v1/service/master/all-masters",},

        {
          name: "Services",
          icon: <GrServices size={css.iconSize2} />,
          master: true,
          child: [
            {
              name: "Services",
              icon: <FaUserMd size={css.iconSize2} />,
              path: "/v1/service/service-master/all-service",
            },
            {
              name: "Packages",
              icon: <BsBagPlus size={css.iconSize2} />,
              path: "/v1/service/service-master/all-package",
            },
            {
              name: "Medicine",
              icon: <RiMedicineBottleLine size={css.iconSize2} />,
              path: "/v1/service/medicine/all-medicine",
            },
          ],
        },
        {
          name: "Settings",
          icon: <MdFilterTiltShift size={css.iconSize2} />,
          path: "/v1/service/master/all-masters",
          master: true,
          child: [
            {
              name: "Sub Speciality",
              icon: <AiOutlineCluster size={css.iconSize2} />,
              path: "/v1/service/subspeciality/all-sub-speciality",
            },
            {
              name: "User Type",
              icon: <TbUserStar size={css.iconSize2} />,
              path: "/v1/service/usertype/alluser-type",
            },
            {
              name: "Employment Type",
              icon: <LiaUserTieSolid size={css.iconSize2} />,
              path: "/v1/service/employementtype/all-employment-type",
            },
            {
              name: "Work Location",
              icon: <BiLocationPlus size={css.iconSize2} />,
              path: "/v1/service/locations/all-locations",
            },
            {
              name: "Portfolio",
              icon: <RiPassportFill size={css.iconSize2} />,
              path: "/v1/service/portfolio/all-portfolio",
            },
            {
              name: "Service Sub Level",
              icon: <MdHomeRepairService size={css.iconSize2} />,
              path: "/v1/service/serviceSubLevel/all-service-sublevels",
            },
          ],
        },
        // {
        //   name: "Services",
        //   icon: <GrServices size={css.iconSize2} />,
        //   path: "/v1/service/serviceRc/all-service-master",
        // },

        // {
        //   name: "Medicine",
        //   icon: <RiMedicineBottleLine size={css.iconSize2} />,
        //   path: "/v1/service/medicine/all-medicine",
        // },
        {
          name: "Reports",
          icon: <GrResources size={css.iconSize2} />,
          master: true,
          child: [
            {
              name: "Detailed Summary by Booking",
              icon: <FaUserMd size={css.iconSize2} />,
              path: "/v1/service/reports/summary-by-booking",
            },
            {
              name: "Detailed Summary by Service",
              icon: <TbFileReport size={css.iconSize2} />,
              path: "/v1/service/reports/summary-by-service",
            },
            {
              name: "Invoice Summary",
              icon: <LiaFileInvoiceSolid size={css.iconSize2} />,
              path: "/v1/service/reports/invoice-summary",
            },
            {
              name: "Invoice Summary Service",
              icon: <BsFileEarmarkMedical size={css.iconSize2} />,
              path: "/v1/service/reports/invoice-summary-service",
            },
          ],
        },
        // {
        //   name: "Supply Request",
        //   icon: <MdOutlineInventory2 size={css.iconSize2} />,
        //   path: "/v1/service/supplyRequest/all-supplyRequest",
        // },
        {
          name: "Audit Log",
          icon: <AiOutlineAudit size={css.iconSize2} />,
          path: "/v1/service/auditlog",
        },
        {
          name: "User Access",
          icon: < GrUserSettings size={css.iconSize2} />,
          master: true,
          child: [
            {
              name: "User Group",
              icon: <HiOutlineUserGroup size={css.iconSize2} />,
              path: "/v1/service/useraccess/user-group",
            },
            {
              name: "User Access",
              icon: <GrResources size={css.iconSize2} />,
              path: "/v1/service/useraccess/all-user-access",
            },

          ],
        },
      ];
    case 4:
      return [
        {
          name: "Dashboard",
          icon: <AiOutlineDashboard size={css.iconSize2} />,
          path: "/v1/service/physiciandashboard",
        },
        {
          name: "Bookings",
          icon: <BsCardList size={css.iconSize2} />,
          path: "/v1/service/appointment/all-appointments",
        },
        {
          name: "Patient",
          icon: <RiShieldUserLine size={css.iconSize2} />,
          path: "/v1/service/patient/all-patient",
        },
        {
          name: "Supply Request",
          icon: <MdOutlineInventory2 size={css.iconSize2} />,
          path: "/v1/service/supplyRequest/all-supplyRequest",
        },
      ];
    case 5:
      return [
        // {
        //   name: "Dashboard",
        //   icon: <AiOutlineDashboard size={css.iconSize2} />,
        //   path: "/v1/service/appointment/patientdashboard",
        // },
        {
          name: "Appointments",
          icon: <BsCalendar2Plus size={css.iconSize2} />,
          path: "/v1/service/appointment/all-appointments",
        },
        {
          name: "Family Members",
          icon: <MdOutlineFamilyRestroom size={css.iconSize2} />,
          path: "/v1/service/familyMembers/all-familyMembers",
        },
        // {
        //   name: "My Notes",
        //   icon: <BsFileEarmarkDiff size={css.iconSize2} />,
        //   path: "/v1/service/appointment/ehr",
        //   child: [],
        // },
        // {
        //   name: "Lab Appointments",
        //   icon: <GiTestTubes size={css.iconSize2} />,
        //   path: "/v1/lab/labappointment/all-labappointment",
        // },
        {
          name: "Add Vitals",
          icon: <TbListDetails size={css.iconSize2} />,
          path: "/v1/service/patient/vitals/all-vitals",
        },
        // {
        //   name: "My Health Records",
        //   icon: <FaNotesMedical size={css.iconSize2} />,
        //   path: "/v1/service/appointment/longitudinal-records",
        // },
      ];
    default:
      return [];
  }
};
